@charset "UTF-8";
/* >> Color Platte */
/* << Color Platte */
/*font family*/
/*font color*/
/*border*/
/*page*/
.bortop {
  position: relative; }
  .bortop:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 1PX;
    width: 100%;
    margin: 0;
    z-index: 1;
    border-top: 1px solid #4b4b5b;
    transform-origin: 0 0;
    transform: scaleY(0.5); }

.borright {
  position: relative; }
  .borright:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1PX;
    margin: 0;
    z-index: 1;
    border-right: 1px solid #4b4b5b;
    transform-origin: 100% 0;
    transform: scaleX(0.5); }

.borbottom {
  position: relative; }
  .borbottom:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1PX;
    margin: 0;
    z-index: 1;
    border-bottom: 1px solid #4b4b5b;
    transform-origin: 0 100%;
    transform: scaleY(0.5); }

.borleft {
  position: relative; }
  .borleft:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1PX;
    margin: 0;
    z-index: 1;
    border-left: 1px solid #4b4b5b;
    transform-origin: 0 0;
    transform: scaleX(0.5); }

.join-main h3 {
  font-size: 20px; }
  .join-main h3 small {
    font-size: 14px; }

.join-main ul {
  list-style: none;
  padding: 0; }

.join-main div h5 {
  font-size: 14px;
  font-weight: bold;
  margin: .5em 0 1em; }

.join-main div a {
  display: block; }
  .join-main div a:link, .join-main div a:visited {
    color: #4b4b4b; }
  .join-main div a:hover {
    color: #2bb699; }

.join-main img {
  display: block;
  transform: scale(0.5);
  transition: all .5s; }
  @media only screen and (max-width: 500px) {
    .join-main img {
      display: none; } }

.job-content {
  overflow-y: auto;
  padding: 10px 0;
  transition: all .3s;
  position: absolute;
  -moz-box-shadow: 2px 2px 7px #aaa;
  -webkit-box-shadow: 2px 2px 7px #aaa;
  box-shadow: 2px 2px 7px #aaa;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  opacity: 0;
  background: #fff; }
  .job-content .close {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 2em;
    right: 2em; }
    .job-content .close img {
      width: 100%; }
  .job-content .main {
    width: 80%;
    overflow-y: auto;
    margin: 70px auto 0 auto; }
    @media only screen and (max-width: 500px) {
      .job-content .main {
        width: 90% !important;
        padding: 0; } }
    .job-content .main .top-img img {
      width: 100%; }
      @media only screen and (max-width: 500px) {
        .job-content .main .top-img img {
          display: none; } }
    .job-content .main .job-desc {
      overflow: hidden;
      overflow-y: auto;
      font-size: 14px; }
      .job-content .main .job-desc p {
        margin: 1em 0; }
      .job-content .main .job-desc li {
        padding: .2em 0; }
      @media only screen and (max-width: 500px) {
        .job-content .main .job-desc {
          height: 230px; } }

.modal-body .job-title {
  padding: .5em 0 1em;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 20px; }
  .modal-body .job-title span {
    color: #4b4b5b; }
  .modal-body .job-title strong {
    font-size: 14px;
    font-weight: 500; }
  @media only screen and (max-width: 500px) {
    .modal-body .job-title {
      padding: 1.5em 0 0 0; } }

.active .join-main img, .keep .join-main img {
  transform: scale(1); }

.active .join-main .img1, .keep .join-main .img1 {
  transition-delay: .1s; }

.active .join-main .img2, .keep .join-main .img2 {
  transition-delay: .2s; }

.active .join-main .img3, .keep .join-main .img3 {
  transition-delay: .3s; }

@media (min-width: 768px) {
  .scale {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-transition: all .5s;
    transition: all .5s; }
    .scale.active {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition-delay: .25s;
      transition-delay: .25s; }
  .scale-left {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-transition: all .5s;
    transition: all .5s; }
  .slideInLeft {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: .25s;
    transition-delay: .25s; }
  .slideInRight {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: .25s;
    transition-delay: .25s; } }

.btn-product {
  border: none;
  padding: 10px 5px;
  text-align: center;
  font-size: 16px; }
  .btn-product:hover {
    background-color: white;
    color: #8737a6; }

.product-btns-box {
  margin-top: 153px; }
  .product-btns-box .btn-default {
    color: #36508b;
    width: 100%;
    max-width: 150px; }
    .product-btns-box .btn-default:hover {
      color: #eebb33;
      box-shadow: 1px 1px 4px #aaa; }
    .product-btns-box .btn-default.active {
      color: white;
      background-color: #6178ea; }
    .product-btns-box .btn-default.active :after {
      color: white;
      background-color: #6178ea; }
  .product-btns-box .btn-primary {
    color: white;
    background-color: #6178ea; }

#product-btns {
  width: 100%;
  position: absolute;
  bottom: 50px;
  transform: translateX(-50%);
  left: 50%;
  max-width: 1170px; }

#product-btns .inline :after {
  content: '';
  display: block;
  height: 22px;
  width: 63px;
  position: absolute;
  top: 72px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 999;
  background: url(../static/solution/common/小三角.png); }

.juhefu-border {
  border-bottom: 1px solid #e9eff4; }
  .juhefu-border .text-grey {
    color: #555; }
  .juhefu-border .juhefu-p {
    color: #808fa3;
    font-size: 18px;
    line-height: 20px; }

.product-img {
  width: 100% !important; }

.discribe {
  color: #555;
  line-height: 34px;
  margin: 0 !important; }

.shangyidai-foot {
  padding: 0;
  position: relative;
  color: #fff;
  font-size: 28px; }
  .shangyidai-foot .col-md-6 {
    height: 345px; }
  .shangyidai-foot .shangyidai-foot-left {
    float: left;
    margin-left: 20%;
    margin-top: 100px; }
  .shangyidai-foot .shangyidai-foot-right {
    float: right;
    margin-right: 20%;
    margin-top: 100px; }
  .shangyidai-foot .shangyidai-foot-title {
    color: #fff;
    font-size: 36px; }

.receivables {
  border: none;
  padding-bottom: 0; }
  .receivables .text-center {
    height: 200px;
    margin-bottom: 50px;
    border-radius: 5px;
    position: relative; }
    .receivables .text-center div {
      background: #f5f6fa;
      padding: 40px 0;
      margin: 0 20px;
      height: 200px; }
      .receivables .text-center div .receivables-title {
        width: 100%;
        color: #666;
        font-size: 16px;
        position: absolute;
        bottom: 20px;
        left: 0; }

.yingxiaobao-member {
  height: 100%; }
  .yingxiaobao-member img {
    margin-top: -100px; }
  .yingxiaobao-member .member-content {
    margin-top: 180px;
    width: 100%; }
    .yingxiaobao-member .member-content p {
      width: 80%;
      color: #666;
      font-size: 18px;
      margin-top: 20px;
      line-height: 30px; }
    .yingxiaobao-member .member-content .member-text {
      width: 90%;
      padding-bottom: 20px;
      border-bottom: 1px solid #e9eff4; }
      .yingxiaobao-member .member-content .member-text img {
        width: 10%;
        padding-right: 20px;
        vertical-align: sub; }
      .yingxiaobao-member .member-content .member-text h3 {
        display: inline; }

.integral {
  height: 100%; }
  .integral img {
    margin-top: 50px;
    margin-left: 10%; }
  .integral .integral-content {
    margin-top: 60px;
    width: 100%;
    margin-left: 10%; }
    .integral .integral-content p {
      width: 80%;
      color: #666;
      font-size: 18px;
      margin-top: 20px;
      line-height: 30px; }
    .integral .integral-content .integral-text {
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #e9eff4; }
      .integral .integral-content .integral-text img {
        width: 10%;
        padding-right: 10px;
        vertical-align: sub; }
      .integral .integral-content .integral-text h3 {
        display: inline; }

.card {
  height: 100%; }
  .card img {
    margin-top: 50px;
    margin-left: 10%; }
  .card .card-content {
    margin-top: 60px;
    width: 90%;
    margin-left: 10%; }
    .card .card-content p {
      width: 85%;
      color: #666;
      font-size: 18px;
      margin-top: 20px;
      line-height: 30px; }
    .card .card-content .card-text {
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #e9eff4; }
      .card .card-content .card-text img {
        width: 10%;
        padding-right: 10px;
        vertical-align: sub; }
      .card .card-content .card-text h3 {
        display: inline; }

.redPacket {
  height: 100%; }
  .redPacket img {
    margin-top: 50px;
    margin-left: 10%; }
  .redPacket .redPacket-content {
    margin-top: 60px;
    width: 90%;
    margin-left: 10%; }
    .redPacket .redPacket-content p {
      width: 100%;
      color: #666;
      font-size: 18px;
      margin-top: 20px;
      line-height: 30px; }
    .redPacket .redPacket-content .redPacket-text {
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #e9eff4; }
      .redPacket .redPacket-content .redPacket-text img {
        width: 10%;
        padding-right: 10px;
        vertical-align: sub;
        margin-left: 0; }
      .redPacket .redPacket-content .redPacket-text h3 {
        display: inline; }

.productBox {
  position: absolute;
  transform: translateY(-50%);
  top: 39%;
  left: 0;
  display: flex;
  align-items: center; }
  .productBox h1 {
    margin: 20px 0 0 0 !important; }

.brand-heading .borderBox {
  position: absolute;
  border: 2px solid #36508b;
  background-color: rgba(255, 255, 255, 0.3);
  width: 700px;
  height: 200px;
  z-index: 0;
  top: 20px;
  left: 2px; }

@media (max-width: 768px) {
  h1 {
    font-size: 27px !important;
    line-height: 1.4em; }
  h2 {
    font-size: 18px !important;
    line-height: 1.4em;
    letter-spacing: 0px !important; }
  .panel {
    font-size: 14px !important; }
  .header-title {
    margin-top: 2em; }
  .home-content {
    opacity: 1 !important; }
  .title h4 {
    font-size: 20px; }
  #product-btns {
    bottom: 20px;
    width: 92%; }
    #product-btns .btn-default {
      background: none; }
    #product-btns .active {
      background: #6178ea; }
    #product-btns .btn-product {
      padding: 5px; }
    #product-btns .inline :after {
      top: 36px; }
  .header-box {
    border: none !important;
    height: 110px !important; }
  .borderBox {
    width: 92% !important;
    height: 110px !important;
    top: 50px !important;
    left: 4% !important; }
  .content {
    top: 50%;
    left: 54px !important;
    padding: 0;
    margin-top: 0px !important; }
    .content h6 {
      font-size: 10px; }
    .content h1 {
      margin-top: 0 !important; }
  .newSection {
    padding: 0 !important; }
    .newSection .title {
      height: 110px !important; }
    .newSection h3 {
      line-height: 110px !important;
      height: 110px !important; }
  .white, .grey {
    padding: 20px 15px 20px !important; }
  .juhefu-p {
    font-size: 12px !important;
    line-height: 14px !important; } }
